import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import currencyFormatter from 'currency-formatter';

dayjs.extend(duration);

const helpers = {
    cutText(text, length) {
        if (text.split(' ').length > 1) {
            const string = text.substring(0, length);
            const splitText = string.split(' ');
            splitText.pop();
            return splitText.join(' ') + '...';
        } else {
            return text;
        }
    },
    formatDate(date, format) {
        return dayjs(date).format(format);
    },
    capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else {
            return '';
        }
    },
    onlyNumber(string) {
        if (string) {
            return string.replace(/\D/g, '');
        } else {
            return '';
        }
    },
    formatCurrency(amount = 0) {
        return currencyFormatter.format(amount, {
            symbol: '₽',
            decimal: '',
            thousand: ' ',
            precision: 0,
            format: '%v %s', // %s is the symbol and %v is the value
        });
    },
    timeAgo(time) {
        const date = new Date((time || '').replace(/-/g, '/').replace(/[TZ]/g, ' '));
        const diff = (new Date().getTime() - date.getTime()) / 1000;
        const dayDiff = Math.floor(diff / 86400);

        if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
            return dayjs(time).format('MMMM DD, YYYY');
        }

        return (
            (dayDiff === 0 &&
                ((diff < 60 && 'just now') ||
                    (diff < 120 && '1 minute ago') ||
                    (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
                    (diff < 7200 && '1 hour ago') ||
                    (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
            (dayDiff === 1 && 'Yesterday') ||
            (dayDiff < 7 && dayDiff + ' days ago') ||
            (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
        );
    },
    diffTimeByNow(time) {
        const startDate = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss').toString());
        const endDate = dayjs(dayjs(time).format('YYYY-MM-DD HH:mm:ss').toString());

        const duration = dayjs.duration(endDate.diff(startDate));
        const milliseconds = Math.floor(duration.asMilliseconds());

        const days = Math.round(milliseconds / 86400000);
        const hours = Math.round((milliseconds % 86400000) / 3600000);
        let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
        const seconds = Math.round((((milliseconds % 86400000) % 3600000) % 60000) / 1000);

        if (seconds < 30 && seconds >= 0) {
            minutes += 1;
        }

        return {
            days: days.toString().length < 2 ? '0' + days : days,
            hours: hours.toString().length < 2 ? '0' + hours : hours,
            minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
            seconds: seconds.toString().length < 2 ? '0' + seconds : seconds,
        };
    },
    isset(obj) {
        if (obj !== null && obj !== undefined) {
            if (typeof obj === 'object' || Array.isArray(obj)) {
                return Object.keys(obj).length;
            } else {
                return obj.toString().length;
            }
        }

        return false;
    },
    toRaw(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    randomNumbers(from, to, length) {
        const numbers = [0];
        for (let i = 1; i < length; i++) {
            numbers.push(Math.ceil(Math.random() * (from - to) + to));
        }

        return numbers;
    },
    allowedMenu(menu, rules, router) {
        const res = [];
        for (const item of menu) {
            if (item.hasOwnProperty('subMenu')) {
                const subMenu = this.allowedMenu(item.subMenu, rules, router);
                if (subMenu.length > 0) {
                    res.push({ ...item, subMenu });
                }
            } else {
                const route = router.resolve({ name: item.pageName });
                if (route && route.meta && route.meta.auth) {
                    if (route.meta.auth.includes(rules)) {
                        res.push(item);
                    }
                }
            }
        }
        return res;
    },
    customTransliterate(text) {
        text = text
            .toLowerCase()
            .replace(/\u0401/g, 'YO')
            .replace(/\u0419/g, 'I')
            .replace(/\u0426/g, 'TS')
            .replace(/\u0423/g, 'U')
            .replace(/\u041A/g, 'K')
            .replace(/\u0415/g, 'E')
            .replace(/\u041D/g, 'N')
            .replace(/\u0413/g, 'G')
            .replace(/\u0428/g, 'SH')
            .replace(/\u0429/g, 'SCH')
            .replace(/\u0417/g, 'Z')
            .replace(/\u0425/g, 'H')
            .replace(/\u042A/g, '')
            .replace(/\u0451/g, 'yo')
            .replace(/\u0439/g, 'i')
            .replace(/\u0446/g, 'ts')
            .replace(/\u0443/g, 'u')
            .replace(/\u043A/g, 'k')
            .replace(/\u0435/g, 'e')
            .replace(/\u043D/g, 'n')
            .replace(/\u0433/g, 'g')
            .replace(/\u0448/g, 'sh')
            .replace(/\u0449/g, 'sch')
            .replace(/\u0437/g, 'z')
            .replace(/\u0445/g, 'h')
            .replace(/\u044A/g, "'")
            .replace(/\u0424/g, 'F')
            .replace(/\u042B/g, 'I')
            .replace(/\u0412/g, 'V')
            .replace(/\u0410/g, 'a')
            .replace(/\u041F/g, 'P')
            .replace(/\u0420/g, 'R')
            .replace(/\u041E/g, 'O')
            .replace(/\u041B/g, 'L')
            .replace(/\u0414/g, 'D')
            .replace(/\u0416/g, 'ZH')
            .replace(/\u042D/g, 'E')
            .replace(/\u0444/g, 'f')
            .replace(/\u044B/g, 'i')
            .replace(/\u0432/g, 'v')
            .replace(/\u0430/g, 'a')
            .replace(/\u043F/g, 'p')
            .replace(/\u0440/g, 'r')
            .replace(/\u043E/g, 'o')
            .replace(/\u043B/g, 'l')
            .replace(/\u0434/g, 'd')
            .replace(/\u0436/g, 'zh')
            .replace(/\u044D/g, 'e')
            .replace(/\u042F/g, 'Ya')
            .replace(/\u0427/g, 'CH')
            .replace(/\u0421/g, 'S')
            .replace(/\u041C/g, 'M')
            .replace(/\u0418/g, 'I')
            .replace(/\u0422/g, 'T')
            .replace(/\u042C/g, "'")
            .replace(/\u0411/g, 'B')
            .replace(/\u042E/g, 'YU')
            .replace(/\u044F/g, 'ya')
            .replace(/\u0447/g, 'ch')
            .replace(/\u0441/g, 's')
            .replace(/\u043C/g, 'm')
            .replace(/\u0438/g, 'i')
            .replace(/\u0442/g, 't')
            .replace(/\u044C/g, "'")
            .replace(/\u0431/g, 'b')
            .replace(/\u044E/g, 'yu')
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '');

        return text;
    },
    setCase(count, cases, numberPosition = null) {
        if (count === undefined || count === null || !Number.isInteger(count)) return '';

        let result = '';

        if (count >= 10 && count <= 20) {
            result = cases[2];
        } else {
            const tmp = count % 10;
            switch (tmp) {
                case 1:
                    result = cases[0];
                    break;
                case 2:
                case 3:
                case 4:
                    result = cases[1];
                    break;
                case 0:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    result = cases[2];
                    break;
            }
        }

        return result;
    },
    getOverrideMessage(message) {
        switch (message) {
            case 'Value is required':
                return 'Обязательное поле';
            case 'Value must be numeric':
                return 'Значение должно быть числом';
            case 'The maximum value allowed is 65535':
                return 'Максимальное значение 65535';
            case 'The maximum value allowed is 99':
                return 'Максимальное значение 99';
            default:
                return message;
        }
    },
};

const install = (app) => {
    app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
