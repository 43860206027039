import Highlight from './highlight/Main.vue';
import Tippy from './tippy/Main.vue';
import TippyContent from './tippy-content/Main.vue';
import LoadingIcon from './loading-icon/Main.vue';
import CKEditor from './ckeditor/Main.vue';
import { Calendar, DatePicker, SetupCalendar } from 'v-calendar';
import * as featherIcons from '@zhuowenli/vue-feather-icons';

export default (app) => {
    app.use(SetupCalendar, {});
    // app.component('Chart', Chart);
    // app.component('GoogleMapLoader', GoogleMapLoader);
    app.component('Highlight', Highlight);
    app.component('Tippy', Tippy);
    app.component('TippyContent', TippyContent);
    // app.component('TomSelect', TomSelect)
    app.component('LoadingIcon', LoadingIcon);
    // app.component('TinySlider', TinySlider);
    app.component('CKEditor', CKEditor);
    app.component('Calendar', Calendar);
    app.component('DatePicker', DatePicker);
    // app.component('FullCalendar', FullCalendar);
    // app.component('FullCalendarDraggable', FullCalendarDraggable);

    for (const [key, icon] of Object.entries(featherIcons)) {
        icon.props.size.default = '24';
        app.component(key, icon);
    }
};
