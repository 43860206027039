import { helper as $h } from '@/utils/helper';

const state = () => {
    return {
        menu: [
            {
                icon: 'HomeIcon', // Иконка для сабменю
                pageName: 'home', // Берет из роутера имя и подставляет его PATH
                title: 'Главная', // Название отображения
            },
            {
                icon: 'LayoutIcon',
                pageName: '',
                title: 'Контент',
                subMenu: [
                    {
                        icon: 'LayoutIcon',
                        pageName: 'pages',
                        title: 'Страницы',
                    },
                    {
                        icon: '',
                        pageName: 'content-banners',
                        title: 'Баннеры',
                    },
                    {
                        icon: 'PaperclipIcon',
                        pageName: 'page-categories',
                        title: 'Категории',
                    },
                    {
                        icon: 'ImageIcon',
                        pageName: 'attractions',
                        title: 'Достопримечательности',
                    },
                    {
                        icon: 'ArrowUpRightIcon',
                        pageName: 'popular-directions',
                        title: 'Популярные направления',
                    },
                    {
                        icon: 'ArrowUpIcon',
                        pageName: 'city-directions',
                        title: 'Города отправлений',
                    },
                    {
                        icon: 'ArrowDownIcon',
                        pageName: 'city-arrivals',
                        title: 'Города прибытий',
                    },
                ],
            },
            {
                icon: 'LayoutIcon',
                pageName: '',
                title: 'Маршруты',
                subMenu: [
                    {
                        icon: 'TargetIcon',
                        pageName: 'points',
                        title: 'Локации',
                    },
                ],
            },
            {
                icon: 'AnchorIcon',
                pageName: 'ships',
                title: 'Теплоходы',
            },
            {
                icon: 'LifeBuoyIcon',
                pageName: 'cruises',
                title: 'Круизы',
            },
            {
                icon: 'TagIcon',
                pageName: 'discounts',
                title: 'Акции',
            },
            {
                icon: 'StarIcon',
                pageName: 'special-discounts',
                title: 'Специальные скидки',
            },
            {
                icon: 'ShoppingCartIcon',
                pageName: 'offers',
                title: 'Спецпредложения',
            },
            {
                icon: 'ThumbsUpIcon',
                pageName: 'reviews',
                title: 'Отзывы',
            },
            {
                icon: 'ActivityIcon',
                pageName: 'indexes',
                title: 'Индексация',
            },
            {
                icon: 'UsersIcon',
                pageName: 'tariffs',
                title: 'Тарифы',
            },
            {
                icon: 'UsersIcon',
                pageName: 'users',
                title: 'Пользователи',
            },
        ],
        formattedMenu: null,
    };
};

// getters
const getters = {
    menu: ({ menu }) => menu,
    formattedMenu: (state) => state.formattedMenu,
};

// actions
const actions = {
    formatMenu({ commit, getters }, { userRules, router }) {
        commit('setFormattedMenu', $h.allowedMenu(getters.menu, userRules, router));
    },
};

// mutations
const mutations = {
    setMenu(state, payload) {
        state.menu = payload;
    },
    clearMenuState(state) {
        state.menu = null;
    },
    setFormattedMenu(state, payload) {
        state.formattedMenu = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
