import * as types from './mutation-types';

const state = () => {
    return {
        darkMode: false,
        breadcrumbs: null,
        pageHeader: 'Dashboard',
        pageCruisesSearchParams: {
            ship: null,
            direction: null,
            departure_date: null,
            arrival_date: null,
        },
    };
};

// getters
const getters = {
    darkMode: (state) => state.darkMode,
    getBreadcrumbs: (state) => state.breadcrumbs,
    getPageHeader: (state) => state.pageHeader,
    getPageCruisesSearchParams(state) {
        return state.pageCruisesSearchParams;
    },
};

// actions
const actions = {
    setDarkMode({ commit }, darkMode) {
        commit(types.SET_DARK_MODE, { darkMode });
    },
    dispatchBreadcrumbs({ commit }, payload) {
        commit('setBreadcrumbs', payload);
    },
};

// mutations
const mutations = {
    [types.SET_DARK_MODE](state, { darkMode }) {
        state.darkMode = darkMode;
    },
    setBreadcrumbs(state, payload) {
        state.breadcrumbs = payload;
    },
    setPageCruisesSearchParams(state, payload) {
        state.pageCruisesSearchParams = payload;
    },
    setPageHeader(state, payload) {
        state.pageHeader = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
