export default [
    {
        path: '/city-directions',
        name: 'city-directions',
        component: () => import('@/views/city-directions/Main.vue'),
        meta: {
            auth: ['super-admin', 'admin', 'manager'],
        },
    },
    {
        path: '/city-directions/create',
        name: 'city-direction-create',
        component: () => import('@/views/city-directions/Create.vue'),
        meta: {
            auth: ['super-admin', 'admin', 'manager'],
        },
    },
    {
        path: '/city-directions/:id/edit',
        name: 'city-direction-edit',
        component: () => import('@/views/city-directions/Edit.vue'),
        meta: {
            auth: ['super-admin', 'admin', 'manager'],
        },
    },
];
