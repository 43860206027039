<template>
    <div>
        <div class="container sm:px-10">
            <div class="block">
                <div class="hidden min-h-screen">
                    <router-link to="/" class="-intro-x flex items-center pt-5">
                        <img alt="logo" class="w-6" :src="require(`@/assets/images/logo.png`)" />
                    </router-link>

                    <div class="my-auto">
                        <img
                            alt="intro"
                            class="-intro-x w-1/2 -mt-16"
                            :src="require(`@/assets/images/illustration.svg`)"
                        />
                    </div>
                </div>

                <div class="h-screen flex py-5 my-10">
                    <form
                        method="post"
                        class="my-auto mx-auto bg-white dark:bg-dark-1 px-5 sm:px-8 py-8 rounded-md shadow-md w-full sm:w-3/4 lg:w-2/4"
                        @submit.prevent="onLoginSubmit"
                    >
                        <h2 class="intro-x font-bold text-2xl text-center">Sign In</h2>
                        <div class="intro-x mt-8">
                            <input
                                v-model="email"
                                type="text"
                                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                                :class="{ 'has-error': error }"
                                placeholder="Email"
                                autocomplete="off"
                            />
                            <input
                                v-model="password"
                                type="password"
                                class="intro-x password__input form-control py-3 px-4 border-gray-300 block mt-4"
                                :class="{ 'has-error': error }"
                                placeholder="Password"
                                autocomplete="off"
                            />
                        </div>
                        <div class="intro-x mt-5 text-center">
                            <button type="submit" class="btn btn-primary py-3 px-4 w-full align-top">Login</button>
                        </div>
                        <div class="text-center mt-4" :class="{ 'show-it': error, hidden: !error }">
                            Неверный логин или пароль
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            email: '',
            password: '',
            error: '',
        };
    },
    computed: {
        ...mapGetters({ getUser: 'user/getUser' }),
    },
    methods: {
        ...mapActions({ login: 'user/login' }),
        async onLoginSubmit() {
            this.error = false;
            if (localStorage.access_token) {
                localStorage.clear();
            }
            const result = await this.login({
                email: this.email,
                password: this.password,
            });
            if (result) {
                await this.$router.push('/');
            } else {
                this.error = true;
            }
        },
    },
};
</script>

<style scoped>
.login__input.has-error,
.password__input.has-error {
    border-color: #9e2626;
}
.show-it {
    display: block;
    color: #9e2626;
}
.hidden {
    display: none;
}
</style>
