<template>
    <div>
        <!--    <MobileMenu />-->

        <div class="flex">
            <nav class="side-nav">
                <router-link to="/" tag="a" class="intro-x flex items-center pl-5 pr-5 pt-4">
                    <img alt="logo" class="w-64" src="@/assets/images/logo.svg" />
                </router-link>

                <div class="side-nav__devider my-6"></div>
                <ul>
                    <!-- BEGIN: First Child -->
                    <template v-for="(menu, menuKey) in formattedMenu">
                        <li v-if="menu == 'devider'" :key="menu + menuKey" class="side-nav__devider my-6"></li>
                        <li v-else :key="menu + menuKey">
                            <SideMenuTooltip
                                tag="a"
                                :content="menu.title"
                                :href="menu.subMenu ? 'javascript:;' : router.resolve({ name: menu.pageName }).path"
                                class="side-menu"
                                :class="{
                                    'side-menu--active': menu.active,
                                    'side-menu--open': menu.activeDropdown,
                                }"
                                @click="linkTo(menu, router, $event)"
                            >
                                <div class="side-menu__icon">
                                    <component :is="menu.icon" />
                                </div>
                                <div class="side-menu__title">
                                    {{ menu.title }}
                                    <div
                                        v-if="menu.subMenu"
                                        class="side-menu__sub-icon"
                                        :class="{ 'transform rotate-180': menu.activeDropdown }"
                                    >
                                        <ChevronDownIcon />
                                    </div>
                                </div>
                            </SideMenuTooltip>
                            <!-- BEGIN: Second Child -->
                            <transition @enter="enter" @leave="leave">
                                <ul v-if="menu.subMenu && menu.activeDropdown">
                                    <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                                        <SideMenuTooltip
                                            tag="a"
                                            :content="subMenu.title"
                                            :href="
                                                subMenu.subMenu
                                                    ? 'javascript:;'
                                                    : router.resolve({ name: subMenu.pageName }).path
                                            "
                                            class="side-menu"
                                            :class="{ 'side-menu--active': subMenu.active }"
                                            @click="linkTo(subMenu, router, $event)"
                                        >
                                            <div class="side-menu__icon">
                                                <div v-if="subMenu.icon" class="side-menu__icon">
                                                    <component :is="subMenu.icon" />
                                                </div>
                                                <ActivityIcon v-else />
                                            </div>
                                            <div class="side-menu__title">
                                                {{ subMenu.title }}
                                                <div
                                                    v-if="subMenu.subMenu"
                                                    class="side-menu__sub-icon"
                                                    :class="{
                                                        'transform rotate-180': subMenu.activeDropdown,
                                                    }"
                                                >
                                                    <ChevronDownIcon />
                                                </div>
                                            </div>
                                        </SideMenuTooltip>
                                        <!-- BEGIN: Third Child -->
                                        <transition @enter="enter" @leave="leave">
                                            <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                                                <li
                                                    v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                                                    :key="lastSubMenuKey"
                                                >
                                                    <SideMenuTooltip
                                                        tag="a"
                                                        :content="lastSubMenu.title"
                                                        :href="
                                                            lastSubMenu.subMenu
                                                                ? 'javascript:;'
                                                                : router.resolve({ name: lastSubMenu.pageName }).path
                                                        "
                                                        class="side-menu"
                                                        :class="{ 'side-menu--active': lastSubMenu.active }"
                                                        @click="linkTo(lastSubMenu, router, $event)"
                                                    >
                                                        <div class="side-menu__icon">
                                                            <ZapIcon />
                                                        </div>
                                                        <div class="side-menu__title">
                                                            {{ lastSubMenu.title }}
                                                        </div>
                                                    </SideMenuTooltip>
                                                </li>
                                            </ul>
                                        </transition>
                                        <!-- END: Third Child -->
                                    </li>
                                </ul>
                            </transition>
                            <!-- END: Second Child -->
                        </li>
                    </template>
                    <!-- END: First Child -->
                </ul>
            </nav>
            <!-- END: Side Menu -->
            <!-- BEGIN: Content -->
            <div class="content">
                <TopBar />
                <router-view />
            </div>
            <!-- END: Content -->
        </div>
        <notifications position="bottom right" />
        <div id="#modal-port" class="modal-port"></div>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { helper as $h } from '@/utils/helper';
import TopBar from '@/components/top-bar/Main.vue';
import MobileMenu from '@/components/mobile-menu/Main.vue';
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue';
import { enter, leave, linkTo, nestedMenu } from './index';

export default defineComponent({
    components: {
        TopBar,
        MobileMenu,
        SideMenuTooltip,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore(); // useStore подключает во Vue3 стор, для доступа и динамичности
        const formattedMenu = ref([]); // ref делает переменную formattedMenu динамичной
        const userRules = store.getters['user/getUserRole'];

        store.dispatch('sideMenu/formatMenu', { userRules, router }); // Форматирует меню, обязательно необходимо для корректеной работы
        const sideMenu = computed(() => nestedMenu(store.getters['sideMenu/formattedMenu'], route));

        watch(
            computed(() => route.path),
            () => {
                formattedMenu.value = $h.toRaw(sideMenu.value);
            }
        );

        onMounted(() => {
            cash('body').removeClass('error-page').removeClass('login').addClass('main');
            formattedMenu.value = $h.toRaw(sideMenu.value);
        });

        return {
            formattedMenu,
            router,
            linkTo,
            enter,
            leave,
        };
    },
});
</script>

<style></style>
