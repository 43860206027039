<template>
    <!-- BEGIN: Top Bar -->
    <div class="top-bar">
        <div class="-intro-x mr-auto hidden sm:flex">
            <h2 class="page__title">{{ pageHeader }}</h2>
        </div>

        <div class="-intro-x mr-auto hidden sm:flex">&nbsp;</div>
        <!-- BEGIN: Account Menu -->
        <div class="intro-x dropdown w-8 h-8">
            <div
                class="w-8 h-8 rounded-full overflow-hidden shadow-lg flex items-center justify-center"
                role="button"
                aria-expanded="false"
                @click="toggleSearchDropdown"
            >
                <UserIcon />
            </div>

            <div v-if="searchDropdown" class="top-bar-dropdown-menu w-56">
                <div class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white">
                    <div class="p-4 border-b border-theme-27 dark:border-dark-3">
                        <div class="font-medium"></div>
                        <!--            <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600"></div>-->
                    </div>
                    <div v-if="showProfile" class="p-2">
                        <a
                            href="#"
                            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                            @click.prevent="onLinkClick(`/users/${user.id}/edit`)"
                        >
                            <UserIcon class="w-4 h-4 mr-2" />
                            Profile
                        </a>
                    </div>
                    <div class="p-2 border-t border-theme-27 dark:border-dark-3">
                        <a
                            href="#"
                            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                            @click.prevent="onUserLogoutClick"
                        >
                            <ToggleRightIcon class="w-4 h-4 mr-2" />
                            Logout
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Account Menu -->
    </div>
    <!-- END: Top Bar -->
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { mapGetters } from 'vuex';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

export default defineComponent({
    setup() {
        const searchDropdown = ref(false),
            toggleSearchDropdown = () => (searchDropdown.value = !searchDropdown.value),
            store = useStore(),
            router = useRouter(),
            user = computed(() => store.getters['user/getUser']),
            userLogout = () => store.dispatch('user/logOut'),
            onLinkClick = (url) => {
                toggleSearchDropdown();
                router.push(url);
            },
            onUserLogoutClick = () => {
                toggleSearchDropdown();
                userLogout();
            };

        let showProfile = false;
        const rolesHasProfile = ['super-admin', 'admin', 'manager', 'rop'];
        const userRole = store.getters['user/getUser'].role;
        if (rolesHasProfile.includes(userRole)) showProfile = true;

        return {
            searchDropdown,
            toggleSearchDropdown,
            user,
            onLinkClick,
            onUserLogoutClick,
            showProfile,
        };
    },
    computed: {
        ...mapGetters({
            pageHeader: 'main/getPageHeader',
        }),
    },
});
</script>

<style lang="scss" scoped>
.top-bar-dropdown-menu {
    position: absolute;
    right: 0;
    top: 40px;
}
</style>
